import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import data from "../../data/salary"

class SalaryNegotiation extends React.Component {
  render() {
    return (
      <Layout hideFooter location={this.props.location} title={"Blog"}>
        <SEO title="Peter Elbaum | Salary Negotiation" />
        <h1>💰 Salary Negotiation</h1>
        <p>I've curated a set of the most helpful resources I've found on salary negotiation. Let me know what else I should add!</p>
        <ul>
          {data.pageLinks.map((item, i) => {
            return (
              <li key={i}>
                <a className="lip-link" href={item.link} rel="noreferrer" target="_blank">
                  {item.title}
                </a>
              </li>
            )
          })}
        </ul>
      </Layout>
    )
  }
}

export default SalaryNegotiation
