const data = {
  pageLinks: [
    {
      title: 'Ten Rules for Negotiating a Job Offer',
      link: 'https://haseebq.com/my-ten-rules-for-negotiating-a-job-offer/',
      author: 'Haseeb Quereshi',
      type: 'article'
    },
    {
      title: 'Salary Negotiation: Make More Money, Be More Valued',
      link: 'https://www.kalzumeus.com/2012/01/23/salary-negotiation/',
      author: 'Patrick McKenzie',
      type: 'article'
    },
    {
      title: 'Chris Voss: The Art of Letting Other People Have Your Way',
      link: 'https://fs.blog/knowledge-project/chris-voss/',
      author: 'Shane Parrish',
      type: 'podcast'
    },
  ],
};

export default data